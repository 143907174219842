<template>
  <div class="sale-manage-sales-list">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <el-input
          size="small"
          v-model="params.keyword"
          placeholder="请输入销售名称或登录账号进行搜索"
          style="width:260px;"
        />

        <el-button type="primary" size="small" @click="search" class="ml-2">搜索</el-button>

        <div class="col"></div>

        <el-button size="small" type="primary" @click="handleAddSales">添加销售员</el-button>
      </template>

      <template slot="table-columns">
        <el-table-column label="编号" width="50">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + ((tableData.page_no - 1) * tableData.page_size) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="销售员名称"/>
        <el-table-column prop="uname" label="登录账号"/>
        <el-table-column label="创建时间" width="197">
          <template slot-scope="scope">{{
              scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss')
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEditShopAssistant(scope.$index, scope.row)">编辑
            </el-button>
            <el-button size="mini" type="danger" @click="handleDeleteShopAssistant(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :page-size="tableData.page_size"
        :total="tableData.data_total"
      />
    </en-table-layout>
    <!--添加-->
    <el-dialog title="添加销售员" :visible.sync="dialogVisible" width="500px" @open="handleDialogOpen"
               :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="salesAddForm" :rules="salesRules" ref="salesAddForm" label-width="120px">
        <el-form-item label="销售员名称" prop="name">
          <el-input v-model="salesAddForm.name" :maxlength="32" clearable placeholder="请输入销售员名称"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" prop="uname">
          <el-input v-model="salesAddForm.uname" :maxlength="20" clearable
                    placeholder="请输入6~20位字母或字母+数字组成的登录账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="salesAddForm.password" :maxlength="20" clearable
                    :placeholder="salesAddForm.member_id ? '不修改请留空' : '请输入登录密码'"></el-input>
        </el-form-item>
        <el-form-item label="">
          提示：密码必须由6~20位字母、数字组成。
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="addSalesForm" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <!--编辑-->
    <el-dialog title="编辑销售员" :visible.sync="editDialogVisible" width="500px" @open="handleDialogOpen"
               :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="salesEditForm" :rules="salesRules" ref="salesEditForm" label-width="120px">
        <el-form-item label="销售员名称" prop="name">
          <el-input v-model="salesEditForm.name" :maxlength="32" clearable placeholder="请输入销售员名称"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" prop="uname">
          <el-input v-model="salesEditForm.uname" :maxlength="20" clearable
                    placeholder="请输入6~20位字母或字母+数字组成的登录账号"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="salesEditForm.password" :maxlength="20" clearable
                    :placeholder="salesEditForm.member_id ? '不修改请留空' : '请输入登录密码'"></el-input>
        </el-form-item>
        <el-form-item label="">
          提示：密码必须由6~20位字母、数字组成。
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="editShopAssistantForm" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Sales from '@/api/sales';
import md5 from 'js-md5';
import Storage from '@/utils/storage';
import {RegExp} from '@/../ui-utils';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'salesList',
  components: {EnTableLayout},
  data() {
    const checkName = (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error('请输入销售员名称,且不能为空格！'));
      } else {
        callback();
      }
    };
    return {
      uuid: Storage.getItem('seller_uuid'),
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        disabled: 0,
      },
      // 列表数据
      tableData: {},
      // 销售员添加表单
      salesAddForm: {},
      // 销售员编辑表单
      salesEditForm: {},
      // 销售员表单 规则
      salesRules: {
        name: [
          {
            required: true,
            message: '请输入销售员名称！',
            trigger: 'bulr'
          },
          {
            validator: checkName,
            trigger: 'blur'
          },
        ],
        uname: [
          {
            required: true,
            message: '请输入销售员账号！',
            trigger: 'bulr'
          },
          {
            validator: (rule, value, callback) => {
              if (
                !RegExp.password.test(value)
              ) {
                callback(new Error('请输入6~20位字母或字母+数字组成的登录账号'));
              } else {
                callback();
              }
            },
          },
        ],
        password: [
          {
            required: true,
            message: '请输入销售员密码！',
            trigger: 'bulr'
          },
          {
            validator: (rule, value, callback) => {
              if (
                !RegExp.password.test(value)
              ) {
                callback(new Error('密码格式错误！'));
              } else {
                callback();
              }
            },
          },
        ],
        mobile: [
          this.MixinRequired('请输入手机号码！'),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error('手机号码格式有误！'));
              } else {
                callback();
              }
            },
          },
        ],
      },
      // 销售员表单 dialog
      dialogVisible: false,
      // 编辑销售员表单
      editDialogVisible: false,
      // 是否是编辑模式 默认不是
      isEdit: false,
      _salesEditForm_: {}
    };
  },
  mounted() {
    this.GET_SalesList();
  },
  activated() {
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_SalesList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_SalesList();
    },

    /** 添加销售员 */
    handleAddSales() {
      this.salesAddForm = {};
      this.dialogVisible = true;
      // 设置添加模式
      this.isEdit = false;
    },

    /** 编辑销售员 */
    handleEditShopAssistant(index, row) {
      this.salesEditForm = this.MixinClone(row);
      this._salesEditForm_ = this.MixinClone(row);
      this.salesEditForm.password = '';
      this.editDialogVisible = true;
      // 设置编辑模式
      this.isEdit = true;
    },

    /** 删除销售员 */
    handleDeleteShopAssistant(index, row) {
      this.$confirm('确定要删除这个销售员吗？', '提示', {type: 'warning'})
        .then(() => {
          API_Sales.delSales(row.member_id).then(() => {
            this.$message.success('删除成功！');
            this.GET_SalesList();
          });
        })
        .catch(() => {
        });
    },

    /** dialog打开后重置form表单校验结果 */
    handleDialogOpen() {
      if (this.isEdit) {
        setTimeout(() => {
          this.$refs['salesEditForm'].clearValidate();
        });
      } else {
      }
    },

    /** 添加销售员表单 */
    addSalesForm() {
      this.$refs['salesAddForm'].validate((valid) => {
        if (valid) {
          const params = this.MixinClone(this.salesAddForm);
          if (params.password) params.password = md5(params.password);
          API_Sales.addSales(params).then(() => {
            this.dialogVisible = false;
            this.$message.success('添加成功！');
            this.GET_SalesList();
          });
        } else {
          this.$message.error('表单填写有误，请核对！');
          return false;
        }
      });
    },

    /** 编辑销售员表单 */
    editShopAssistantForm() {
      this.$refs['salesEditForm'].validate((valid) => {
        if (valid) {
          const params = {};
          const _params = this.MixinClone(this.salesEditForm);
          if (_params.password) params.password = md5(_params.password);
          if (_params.uname !== this._salesEditForm_.uname) params.uname = _params.uname;
          if (_params.name !== this._salesEditForm_.name) params.name = _params.name;
          params.id = _params.member_id;
          if (Object.keys(params).length === 1) {
            this.editDialogVisible = false;
          } else {
            API_Sales.editSales(params).then(() => {
              this.editDialogVisible = false;
              this.$message.success('修改成功！');
              this.GET_SalesList();
            });
          }
        } else {
          this.$message.error('表单填写有误，请核对！');
          return false;
        }
      });
    },

    search() {
      this.GET_SalesList();
    },

    /** 获取销售员列表 */
    GET_SalesList() {
      this.loading = true;
      API_Sales.listSales(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.face-image {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}
</style>
