/**
 * 销售员相关API
 */

import request from '@/utils/request'

/**
 * 获取销售员列表
 * @param data
 * @returns {Promise<any>}
 */
export function listSales(data) {
  return request({
    url: 'seller/members/getSell',
    method: 'post',
    data
  })
}

/**
 * 添加销售员
 * @param data
 * @returns {Promise<any>}
 */
export function addSales(data) {
  return request({
    url: 'seller/members/addSell',
    method: 'post',
    data
  })
}

/**
 * 删除销售员
 * @param id
 * @returns {Promise<any>}
 */
export function delSales(id) {
  return request({
    url: `seller/members/delSell/${id}`,
    method: 'delete'
  })
}

/**
 * 查询销售员
 * @param id
 * @returns {Promise<any>}
 */
export function getSales(id) {
  return request({
    url: `seller/members/${id}`,
    method: 'get'
  })
}

/**
 * 编辑销售员
 * @param data
 * @returns {Promise<any>}
 */
export function editSales(data) {
  return request({
    url: 'seller/members/editSell',
    method: 'put',
    data
  })
}
